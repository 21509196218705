// this is a preset used in app admin when creating users details, as most of them are hub creators

import userDetails from "./userDetails.js"
import merge from "lodash/merge.js"
import cloneDeep from "lodash/cloneDeep.js"

const overrides = {
  channels: {
    hub: {
      profileVerification: {
        verified: true,
      },
      profileAtlaseekApproved: {
        approved: true,
      },
      profileVisibility: {
        url: true,
        listing: true,
      },
    },
  },
}

export default merge(cloneDeep(userDetails), overrides)
