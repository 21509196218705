import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"

export const useAppStore = defineStore("app", () => {
  // state
  const app = ref({
    darkMode: localStorage.uiDarkMode || "system", // light | dark | system
    testMode: localStorage.testMode?.toLowerCase() === "true" || false,
    scrollY: 0,
    viewport: {
      width: null,
      height: null,
      size: null, // approximated breakpoint
      currentBreakpoint: null,
      breakpoints: {
        // has to be same as tailwind.config.cjs
        xs: 375,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    },
  })

  return {
    // state
    app,

    // actions
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
