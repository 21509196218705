import enumsProfileLocationKinds from "../enums/profileLocationKinds.js"

const optionsProfileLocationKinds = enumsProfileLocationKinds.map((kind) => {
  return {
    value: kind.id,
    label: kind.title,
  }
})

export default optionsProfileLocationKinds
