import {request} from "../request.js"

export const dictionaryCreate = async (categoryId, data, opts) => {
  return request({
    endpoint: `dictionary/${categoryId}`,
    method: "POST",
    data: {data},
    opts,
  })
}

export const dictionaryGet = async (categoryId, entryId, opts) => {
  const params = new URLSearchParams()

  if (opts.include) {
    params.append("include", opts.include)
  }

  const query = params.toString() ? `?${params.toString()}` : ""

  const endpoint = entryId
    ? `dictionary/${categoryId}/${entryId}${query}`
    : categoryId
    ? `dictionary/${categoryId}${query}`
    : `dictionary${query}`

  return request({
    endpoint,
    method: "GET",
    opts,
  })
}

export const dictionaryUpdate = async (categoryId, entryId, data, opts) => {
  return request({
    endpoint: `dictionary/${categoryId}/${entryId}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const dictionaryDelete = async (categoryId, entryId, opts) => {
  return request({
    endpoint: `dictionary/${categoryId}/${entryId}`,
    method: "DELETE",
    opts,
  })
}
