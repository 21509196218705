export default [
  {
    id: "instagramPost",
    title: "instagram post",
    titleShort: "post",
    platform: "instagram",
  },
  {
    id: "instagramReel",
    title: "instagram reel",
    titleShort: "reel",
    platform: "instagram",
  },
  {
    id: "instagramStory",
    title: "instagram story",
    titleShort: "story",
    platform: "instagram",
  },
  {
    id: "tiktokVideo",
    title: "tiktok video",
    titleShort: "video",
    platform: "tiktok",
  },
  {
    id: "youtubeVideo",
    title: "youtube video",
    titleShort: "video",
    platform: "youtube",
  },
  {
    id: "youtubeShort",
    title: "youtube short",
    titleShort: "short",
    platform: "youtube",
  },
]
