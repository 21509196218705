// this is a preset used in app admin when creating users, as most of them are hub creators

import merge from "lodash/merge.js"
import cloneDeep from "lodash/cloneDeep.js"
import user from "./user.js"

const overrides = {
  channels: {
    explore: {
      enabled: true,
      plan: "exploreExplorerFree",
      roles: ["exploreExplorer"],
    },
    hub: {
      enabled: true,
      plan: "hubCreatorFree",
      roles: ["hubCreator"],
    },
  },
}

export default merge(cloneDeep(user), overrides)
