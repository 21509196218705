import enumsPlatformsContentKinds from "../enums/platformsContentKinds.js"

const optionsPlatformsContentKindsShort = enumsPlatformsContentKinds.map((platform) => {
  return {
    value: platform.id,
    label: platform.titleShort,
    platform: platform.platform,
  }
})

export default optionsPlatformsContentKindsShort
