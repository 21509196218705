import enumsProfileAtlaseekApprovedStatuses from "../enums/profileAtlaseekApprovedStatuses.js"

const optionsProfileAtlaseekApprovedStatuses =
  enumsProfileAtlaseekApprovedStatuses.map((status) => {
    return {
      value: status.id,
      label: status.title,
    }
  })

export default optionsProfileAtlaseekApprovedStatuses
