export default [
  {
    id: "pending",
    title: "pending",
  },
  {
    id: "verified",
    title: "verified",
  },
  {
    id: "rejected",
    title: "rejected",
  },
]
