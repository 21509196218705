export default [
  {
    id: "hq",
    title: "HQ",
  },
  {
    id: "operating",
    title: "operating",
  },
]
