import enumsCollaborationStatuses from "../enums/collaborationStatuses.js"

const optionsCollaborationStatuses = enumsCollaborationStatuses.map(
  (status) => {
    return {
      value: status.id,
      label: status.title,
    }
  }
)

export default optionsCollaborationStatuses
