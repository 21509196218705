import enumsProfileVerificationStatuses from "../enums/profileVerificationStatuses.js"

const optionsProfileVerificationStatuses = enumsProfileVerificationStatuses.map(
  (status) => {
    return {
      value: status.id,
      label: status.title,
    }
  }
)

export default optionsProfileVerificationStatuses
