export default [
  {
    id: "draft",
    title: "draft",
  },
  {
    id: "published",
    title: "published",
  },
  {
    id: "unpublished",
    title: "unpublished",
  },
  {
    id: "inReview",
    title: "in review",
  },
  {
    id: "archived",
    title: "archived",
  },
]
