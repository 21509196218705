export default [
  {
    id: "open",
    title: "open",
  },
  {
    id: "approved",
    title: "approved",
  },
  {
    id: "scheduled",
    title: "scheduled",
  },
  {
    id: "no_show",
    title: "no show",
  },
  {
    id: "visited",
    title: "visited",
  },
  {
    id: "waiting_for_content",
    title: "waiting for content",
  },
  {
    id: "content_approved",
    title: "content approved",
  },
  {
    id: "content_rejected",
    title: "content rejected",
  },
  {
    id: "fulfilled",
    title: "fulfilled",
  },
  {
    id: "rejected",
    title: "rejected",
  },
  {
    id: "archived",
    title: "archived",
  },
]
