export default [
  {
    slug: "taiwan",
    iso2: "TW",
    iso3: "TWN",
    cldr: "Taiwan",
  },
  {
    slug: "afghanistan",
    iso2: "AF",
    iso3: "AFG",
    cldr: "Afghanistan",
  },
  {
    slug: "albania",
    iso2: "AL",
    iso3: "ALB",
    cldr: "Albania",
  },
  {
    slug: "algeria",
    iso2: "DZ",
    iso3: "DZA",
    cldr: "Algeria",
  },
  {
    slug: "andorra",
    iso2: "AD",
    iso3: "AND",
    cldr: "Andorra",
  },
  {
    slug: "angola",
    iso2: "AO",
    iso3: "AGO",
    cldr: "Angola",
  },
  {
    slug: "antigua-&-barbuda",
    iso2: "AG",
    iso3: "ATG",
    cldr: "Antigua & Barbuda",
  },
  {
    slug: "argentina",
    iso2: "AR",
    iso3: "ARG",
    cldr: "Argentina",
  },
  {
    slug: "armenia",
    iso2: "AM",
    iso3: "ARM",
    cldr: "Armenia",
  },
  {
    slug: "australia",
    iso2: "AU",
    iso3: "AUS",
    cldr: "Australia",
  },
  {
    slug: "austria",
    iso2: "AT",
    iso3: "AUT",
    cldr: "Austria",
  },
  {
    slug: "azerbaijan",
    iso2: "AZ",
    iso3: "AZE",
    cldr: "Azerbaijan",
  },
  {
    slug: "bahamas",
    iso2: "BS",
    iso3: "BHS",
    cldr: "Bahamas",
  },
  {
    slug: "bahrain",
    iso2: "BH",
    iso3: "BHR",
    cldr: "Bahrain",
  },
  {
    slug: "bangladesh",
    iso2: "BD",
    iso3: "BGD",
    cldr: "Bangladesh",
  },
  {
    slug: "barbados",
    iso2: "BB",
    iso3: "BRB",
    cldr: "Barbados",
  },
  {
    slug: "belarus",
    iso2: "BY",
    iso3: "BLR",
    cldr: "Belarus",
  },
  {
    slug: "belgium",
    iso2: "BE",
    iso3: "BEL",
    cldr: "Belgium",
  },
  {
    slug: "belize",
    iso2: "BZ",
    iso3: "BLZ",
    cldr: "Belize",
  },
  {
    slug: "benin",
    iso2: "BJ",
    iso3: "BEN",
    cldr: "Benin",
  },
  {
    slug: "bhutan",
    iso2: "BT",
    iso3: "BTN",
    cldr: "Bhutan",
  },
  {
    slug: "bolivia",
    iso2: "BO",
    iso3: "BOL",
    cldr: "Bolivia",
  },
  {
    slug: "bosnia",
    iso2: "BA",
    iso3: "BIH",
    cldr: "Bosnia",
  },
  {
    slug: "botswana",
    iso2: "BW",
    iso3: "BWA",
    cldr: "Botswana",
  },
  {
    slug: "brazil",
    iso2: "BR",
    iso3: "BRA",
    cldr: "Brazil",
  },
  {
    slug: "brunei",
    iso2: "BN",
    iso3: "BRN",
    cldr: "Brunei",
  },
  {
    slug: "bulgaria",
    iso2: "BG",
    iso3: "BGR",
    cldr: "Bulgaria",
  },
  {
    slug: "burkina-faso",
    iso2: "BF",
    iso3: "BFA",
    cldr: "Burkina Faso",
  },
  {
    slug: "burundi",
    iso2: "BI",
    iso3: "BDI",
    cldr: "Burundi",
  },
  {
    slug: "cape-verde",
    iso2: "CV",
    iso3: "CPV",
    cldr: "Cape Verde",
  },
  {
    slug: "cambodia",
    iso2: "KH",
    iso3: "KHM",
    cldr: "Cambodia",
  },
  {
    slug: "cameroon",
    iso2: "CM",
    iso3: "CMR",
    cldr: "Cameroon",
  },
  {
    slug: "canada",
    iso2: "CA",
    iso3: "CAN",
    cldr: "Canada",
  },
  {
    slug: "central-african-republic",
    iso2: "CF",
    iso3: "CAF",
    cldr: "Central African Republic",
  },
  {
    slug: "chad",
    iso2: "TD",
    iso3: "TCD",
    cldr: "Chad",
  },
  {
    slug: "chile",
    iso2: "CL",
    iso3: "CHL",
    cldr: "Chile",
  },
  {
    slug: "china",
    iso2: "CN",
    iso3: "CHN",
    cldr: "China",
  },
  {
    slug: "colombia",
    iso2: "CO",
    iso3: "COL",
    cldr: "Colombia",
  },
  {
    slug: "comoros",
    iso2: "KM",
    iso3: "COM",
    cldr: "Comoros",
  },
  {
    slug: "congo---brazzaville",
    iso2: "CG",
    iso3: "COG",
    cldr: "Congo - Brazzaville",
  },
  {
    slug: "costa-rica",
    iso2: "CR",
    iso3: "CRI",
    cldr: "Costa Rica",
  },
  {
    slug: "croatia",
    iso2: "HR",
    iso3: "HRV",
    cldr: "Croatia",
  },
  {
    slug: "cuba",
    iso2: "CU",
    iso3: "CUB",
    cldr: "Cuba",
  },
  {
    slug: "cyprus",
    iso2: "CY",
    iso3: "CYP",
    cldr: "Cyprus",
  },
  {
    slug: "czechia",
    iso2: "CZ",
    iso3: "CZE",
    cldr: "Czechia",
  },
  {
    slug: "côte-d’ivoire",
    iso2: "CI",
    iso3: "CIV",
    cldr: "Côte d’Ivoire",
  },
  {
    slug: "north-korea",
    iso2: "KP",
    iso3: "PRK",
    cldr: "North Korea",
  },
  {
    slug: "congo---kinshasa",
    iso2: "CD",
    iso3: "COD",
    cldr: "Congo - Kinshasa",
  },
  {
    slug: "denmark",
    iso2: "DK",
    iso3: "DNK",
    cldr: "Denmark",
  },
  {
    slug: "djibouti",
    iso2: "DJ",
    iso3: "DJI",
    cldr: "Djibouti",
  },
  {
    slug: "dominica",
    iso2: "DM",
    iso3: "DMA",
    cldr: "Dominica",
  },
  {
    slug: "dominican-republic",
    iso2: "DO",
    iso3: "DOM",
    cldr: "Dominican Republic",
  },
  {
    slug: "ecuador",
    iso2: "EC",
    iso3: "ECU",
    cldr: "Ecuador",
  },
  {
    slug: "egypt",
    iso2: "EG",
    iso3: "EGY",
    cldr: "Egypt",
  },
  {
    slug: "el-salvador",
    iso2: "SV",
    iso3: "SLV",
    cldr: "El Salvador",
  },
  {
    slug: "equatorial-guinea",
    iso2: "GQ",
    iso3: "GNQ",
    cldr: "Equatorial Guinea",
  },
  {
    slug: "eritrea",
    iso2: "ER",
    iso3: "ERI",
    cldr: "Eritrea",
  },
  {
    slug: "estonia",
    iso2: "EE",
    iso3: "EST",
    cldr: "Estonia",
  },
  {
    slug: "eswatini",
    iso2: "SZ",
    iso3: "SWZ",
    cldr: "Eswatini",
  },
  {
    slug: "ethiopia",
    iso2: "ET",
    iso3: "ETH",
    cldr: "Ethiopia",
  },
  {
    slug: "fiji",
    iso2: "FJ",
    iso3: "FJI",
    cldr: "Fiji",
  },
  {
    slug: "finland",
    iso2: "FI",
    iso3: "FIN",
    cldr: "Finland",
  },
  {
    slug: "france",
    iso2: "FR",
    iso3: "FRA",
    cldr: "France",
  },
  {
    slug: "gabon",
    iso2: "GA",
    iso3: "GAB",
    cldr: "Gabon",
  },
  {
    slug: "gambia",
    iso2: "GM",
    iso3: "GMB",
    cldr: "Gambia",
  },
  {
    slug: "georgia",
    iso2: "GE",
    iso3: "GEO",
    cldr: "Georgia",
  },
  {
    slug: "germany",
    iso2: "DE",
    iso3: "DEU",
    cldr: "Germany",
  },
  {
    slug: "ghana",
    iso2: "GH",
    iso3: "GHA",
    cldr: "Ghana",
  },
  {
    slug: "greece",
    iso2: "GR",
    iso3: "GRC",
    cldr: "Greece",
  },
  {
    slug: "grenada",
    iso2: "GD",
    iso3: "GRD",
    cldr: "Grenada",
  },
  {
    slug: "guatemala",
    iso2: "GT",
    iso3: "GTM",
    cldr: "Guatemala",
  },
  {
    slug: "guinea",
    iso2: "GN",
    iso3: "GIN",
    cldr: "Guinea",
  },
  {
    slug: "guinea-bissau",
    iso2: "GW",
    iso3: "GNB",
    cldr: "Guinea-Bissau",
  },
  {
    slug: "guyana",
    iso2: "GY",
    iso3: "GUY",
    cldr: "Guyana",
  },
  {
    slug: "haiti",
    iso2: "HT",
    iso3: "HTI",
    cldr: "Haiti",
  },
  {
    slug: "vatican-city",
    iso2: "VA",
    iso3: "VAT",
    cldr: "Vatican City",
  },
  {
    slug: "honduras",
    iso2: "HN",
    iso3: "HND",
    cldr: "Honduras",
  },
  {
    slug: "hungary",
    iso2: "HU",
    iso3: "HUN",
    cldr: "Hungary",
  },
  {
    slug: "iceland",
    iso2: "IS",
    iso3: "ISL",
    cldr: "Iceland",
  },
  {
    slug: "india",
    iso2: "IN",
    iso3: "IND",
    cldr: "India",
  },
  {
    slug: "indonesia",
    iso2: "ID",
    iso3: "IDN",
    cldr: "Indonesia",
  },
  {
    slug: "iran",
    iso2: "IR",
    iso3: "IRN",
    cldr: "Iran",
  },
  {
    slug: "iraq",
    iso2: "IQ",
    iso3: "IRQ",
    cldr: "Iraq",
  },
  {
    slug: "ireland",
    iso2: "IE",
    iso3: "IRL",
    cldr: "Ireland",
  },
  {
    slug: "israel",
    iso2: "IL",
    iso3: "ISR",
    cldr: "Israel",
  },
  {
    slug: "italy",
    iso2: "IT",
    iso3: "ITA",
    cldr: "Italy",
  },
  {
    slug: "jamaica",
    iso2: "JM",
    iso3: "JAM",
    cldr: "Jamaica",
  },
  {
    slug: "japan",
    iso2: "JP",
    iso3: "JPN",
    cldr: "Japan",
  },
  {
    slug: "jordan",
    iso2: "JO",
    iso3: "JOR",
    cldr: "Jordan",
  },
  {
    slug: "kazakhstan",
    iso2: "KZ",
    iso3: "KAZ",
    cldr: "Kazakhstan",
  },
  {
    slug: "kenya",
    iso2: "KE",
    iso3: "KEN",
    cldr: "Kenya",
  },
  {
    slug: "kiribati",
    iso2: "KI",
    iso3: "KIR",
    cldr: "Kiribati",
  },
  {
    slug: "kuwait",
    iso2: "KW",
    iso3: "KWT",
    cldr: "Kuwait",
  },
  {
    slug: "kyrgyzstan",
    iso2: "KG",
    iso3: "KGZ",
    cldr: "Kyrgyzstan",
  },
  {
    slug: "laos",
    iso2: "LA",
    iso3: "LAO",
    cldr: "Laos",
  },
  {
    slug: "latvia",
    iso2: "LV",
    iso3: "LVA",
    cldr: "Latvia",
  },
  {
    slug: "lebanon",
    iso2: "LB",
    iso3: "LBN",
    cldr: "Lebanon",
  },
  {
    slug: "lesotho",
    iso2: "LS",
    iso3: "LSO",
    cldr: "Lesotho",
  },
  {
    slug: "liberia",
    iso2: "LR",
    iso3: "LBR",
    cldr: "Liberia",
  },
  {
    slug: "libya",
    iso2: "LY",
    iso3: "LBY",
    cldr: "Libya",
  },
  {
    slug: "liechtenstein",
    iso2: "LI",
    iso3: "LIE",
    cldr: "Liechtenstein",
  },
  {
    slug: "lithuania",
    iso2: "LT",
    iso3: "LTU",
    cldr: "Lithuania",
  },
  {
    slug: "luxembourg",
    iso2: "LU",
    iso3: "LUX",
    cldr: "Luxembourg",
  },
  {
    slug: "madagascar",
    iso2: "MG",
    iso3: "MDG",
    cldr: "Madagascar",
  },
  {
    slug: "malawi",
    iso2: "MW",
    iso3: "MWI",
    cldr: "Malawi",
  },
  {
    slug: "malaysia",
    iso2: "MY",
    iso3: "MYS",
    cldr: "Malaysia",
  },
  {
    slug: "maldives",
    iso2: "MV",
    iso3: "MDV",
    cldr: "Maldives",
  },
  {
    slug: "mali",
    iso2: "ML",
    iso3: "MLI",
    cldr: "Mali",
  },
  {
    slug: "malta",
    iso2: "MT",
    iso3: "MLT",
    cldr: "Malta",
  },
  {
    slug: "marshall-islands",
    iso2: "MH",
    iso3: "MHL",
    cldr: "Marshall Islands",
  },
  {
    slug: "mauritania",
    iso2: "MR",
    iso3: "MRT",
    cldr: "Mauritania",
  },
  {
    slug: "mauritius",
    iso2: "MU",
    iso3: "MUS",
    cldr: "Mauritius",
  },
  {
    slug: "mexico",
    iso2: "MX",
    iso3: "MEX",
    cldr: "Mexico",
  },
  {
    slug: "micronesia",
    iso2: "FM",
    iso3: "FSM",
    cldr: "Micronesia",
  },
  {
    slug: "monaco",
    iso2: "MC",
    iso3: "MCO",
    cldr: "Monaco",
  },
  {
    slug: "mongolia",
    iso2: "MN",
    iso3: "MNG",
    cldr: "Mongolia",
  },
  {
    slug: "montenegro",
    iso2: "ME",
    iso3: "MNE",
    cldr: "Montenegro",
  },
  {
    slug: "morocco",
    iso2: "MA",
    iso3: "MAR",
    cldr: "Morocco",
  },
  {
    slug: "mozambique",
    iso2: "MZ",
    iso3: "MOZ",
    cldr: "Mozambique",
  },
  {
    slug: "myanmar",
    iso2: "MM",
    iso3: "MMR",
    cldr: "Myanmar",
  },
  {
    slug: "namibia",
    iso2: "NA",
    iso3: "NAM",
    cldr: "Namibia",
  },
  {
    slug: "nauru",
    iso2: "NR",
    iso3: "NRU",
    cldr: "Nauru",
  },
  {
    slug: "nepal",
    iso2: "NP",
    iso3: "NPL",
    cldr: "Nepal",
  },
  {
    slug: "netherlands",
    iso2: "NL",
    iso3: "NLD",
    cldr: "Netherlands",
  },
  {
    slug: "new-zealand",
    iso2: "NZ",
    iso3: "NZL",
    cldr: "New Zealand",
  },
  {
    slug: "nicaragua",
    iso2: "NI",
    iso3: "NIC",
    cldr: "Nicaragua",
  },
  {
    slug: "niger",
    iso2: "NE",
    iso3: "NER",
    cldr: "Niger",
  },
  {
    slug: "nigeria",
    iso2: "NG",
    iso3: "NGA",
    cldr: "Nigeria",
  },
  {
    slug: "norway",
    iso2: "NO",
    iso3: "NOR",
    cldr: "Norway",
  },
  {
    slug: "oman",
    iso2: "OM",
    iso3: "OMN",
    cldr: "Oman",
  },
  {
    slug: "pakistan",
    iso2: "PK",
    iso3: "PAK",
    cldr: "Pakistan",
  },
  {
    slug: "palau",
    iso2: "PW",
    iso3: "PLW",
    cldr: "Palau",
  },
  {
    slug: "panama",
    iso2: "PA",
    iso3: "PAN",
    cldr: "Panama",
  },
  {
    slug: "papua-new-guinea",
    iso2: "PG",
    iso3: "PNG",
    cldr: "Papua New Guinea",
  },
  {
    slug: "paraguay",
    iso2: "PY",
    iso3: "PRY",
    cldr: "Paraguay",
  },
  {
    slug: "peru",
    iso2: "PE",
    iso3: "PER",
    cldr: "Peru",
  },
  {
    slug: "philippines",
    iso2: "PH",
    iso3: "PHL",
    cldr: "Philippines",
  },
  {
    slug: "poland",
    iso2: "PL",
    iso3: "POL",
    cldr: "Poland",
  },
  {
    slug: "portugal",
    iso2: "PT",
    iso3: "PRT",
    cldr: "Portugal",
  },
  {
    slug: "qatar",
    iso2: "QA",
    iso3: "QAT",
    cldr: "Qatar",
  },
  {
    slug: "south-korea",
    iso2: "KR",
    iso3: "KOR",
    cldr: "South Korea",
  },
  {
    slug: "moldova",
    iso2: "MD",
    iso3: "MDA",
    cldr: "Moldova",
  },
  {
    slug: "romania",
    iso2: "RO",
    iso3: "ROU",
    cldr: "Romania",
  },
  {
    slug: "russia",
    iso2: "RU",
    iso3: "RUS",
    cldr: "Russia",
  },
  {
    slug: "rwanda",
    iso2: "RW",
    iso3: "RWA",
    cldr: "Rwanda",
  },
  {
    slug: "st.-kitts-&-nevis",
    iso2: "KN",
    iso3: "KNA",
    cldr: "St. Kitts & Nevis",
  },
  {
    slug: "st.-lucia",
    iso2: "LC",
    iso3: "LCA",
    cldr: "St. Lucia",
  },
  {
    slug: "st.-vincent-&-grenadines",
    iso2: "VC",
    iso3: "VCT",
    cldr: "St. Vincent & Grenadines",
  },
  {
    slug: "samoa",
    iso2: "WS",
    iso3: "WSM",
    cldr: "Samoa",
  },
  {
    slug: "san-marino",
    iso2: "SM",
    iso3: "SMR",
    cldr: "San Marino",
  },
  {
    slug: "são-tomé-&-príncipe",
    iso2: "ST",
    iso3: "STP",
    cldr: "São Tomé & Príncipe",
  },
  {
    slug: "saudi-arabia",
    iso2: "SA",
    iso3: "SAU",
    cldr: "Saudi Arabia",
  },
  {
    slug: "senegal",
    iso2: "SN",
    iso3: "SEN",
    cldr: "Senegal",
  },
  {
    slug: "serbia",
    iso2: "RS",
    iso3: "SRB",
    cldr: "Serbia",
  },
  {
    slug: "seychelles",
    iso2: "SC",
    iso3: "SYC",
    cldr: "Seychelles",
  },
  {
    slug: "sierra-leone",
    iso2: "SL",
    iso3: "SLE",
    cldr: "Sierra Leone",
  },
  {
    slug: "singapore",
    iso2: "SG",
    iso3: "SGP",
    cldr: "Singapore",
  },
  {
    slug: "slovakia",
    iso2: "SK",
    iso3: "SVK",
    cldr: "Slovakia",
  },
  {
    slug: "slovenia",
    iso2: "SI",
    iso3: "SVN",
    cldr: "Slovenia",
  },
  {
    slug: "solomon-islands",
    iso2: "SB",
    iso3: "SLB",
    cldr: "Solomon Islands",
  },
  {
    slug: "somalia",
    iso2: "SO",
    iso3: "SOM",
    cldr: "Somalia",
  },
  {
    slug: "south-africa",
    iso2: "ZA",
    iso3: "ZAF",
    cldr: "South Africa",
  },
  {
    slug: "south-sudan",
    iso2: "SS",
    iso3: "SSD",
    cldr: "South Sudan",
  },
  {
    slug: "spain",
    iso2: "ES",
    iso3: "ESP",
    cldr: "Spain",
  },
  {
    slug: "sri-lanka",
    iso2: "LK",
    iso3: "LKA",
    cldr: "Sri Lanka",
  },
  {
    slug: "sudan",
    iso2: "SD",
    iso3: "SDN",
    cldr: "Sudan",
  },
  {
    slug: "suriname",
    iso2: "SR",
    iso3: "SUR",
    cldr: "Suriname",
  },
  {
    slug: "sweden",
    iso2: "SE",
    iso3: "SWE",
    cldr: "Sweden",
  },
  {
    slug: "switzerland",
    iso2: "CH",
    iso3: "CHE",
    cldr: "Switzerland",
  },
  {
    slug: "syria",
    iso2: "SY",
    iso3: "SYR",
    cldr: "Syria",
  },
  {
    slug: "tajikistan",
    iso2: "TJ",
    iso3: "TJK",
    cldr: "Tajikistan",
  },
  {
    slug: "thailand",
    iso2: "TH",
    iso3: "THA",
    cldr: "Thailand",
  },
  {
    slug: "north-macedonia",
    iso2: "MK",
    iso3: "MKD",
    cldr: "North Macedonia",
  },
  {
    slug: "timor-leste",
    iso2: "TL",
    iso3: "TLS",
    cldr: "Timor-Leste",
  },
  {
    slug: "togo",
    iso2: "TG",
    iso3: "TGO",
    cldr: "Togo",
  },
  {
    slug: "tonga",
    iso2: "TO",
    iso3: "TON",
    cldr: "Tonga",
  },
  {
    slug: "trinidad-&-tobago",
    iso2: "TT",
    iso3: "TTO",
    cldr: "Trinidad & Tobago",
  },
  {
    slug: "tunisia",
    iso2: "TN",
    iso3: "TUN",
    cldr: "Tunisia",
  },
  {
    slug: "turkey",
    iso2: "TR",
    iso3: "TUR",
    cldr: "Turkey",
  },
  {
    slug: "turkmenistan",
    iso2: "TM",
    iso3: "TKM",
    cldr: "Turkmenistan",
  },
  {
    slug: "tuvalu",
    iso2: "TV",
    iso3: "TUV",
    cldr: "Tuvalu",
  },
  {
    slug: "uganda",
    iso2: "UG",
    iso3: "UGA",
    cldr: "Uganda",
  },
  {
    slug: "ukraine",
    iso2: "UA",
    iso3: "UKR",
    cldr: "Ukraine",
  },
  {
    slug: "united-arab-emirates",
    iso2: "AE",
    iso3: "ARE",
    cldr: "United Arab Emirates",
  },
  {
    slug: "uk",
    iso2: "GB",
    iso3: "GBR",
    cldr: "UK",
  },
  {
    slug: "tanzania",
    iso2: "TZ",
    iso3: "TZA",
    cldr: "Tanzania",
  },
  {
    slug: "us",
    iso2: "US",
    iso3: "USA",
    cldr: "US",
  },
  {
    slug: "uruguay",
    iso2: "UY",
    iso3: "URY",
    cldr: "Uruguay",
  },
  {
    slug: "uzbekistan",
    iso2: "UZ",
    iso3: "UZB",
    cldr: "Uzbekistan",
  },
  {
    slug: "vanuatu",
    iso2: "VU",
    iso3: "VUT",
    cldr: "Vanuatu",
  },
  {
    slug: "venezuela",
    iso2: "VE",
    iso3: "VEN",
    cldr: "Venezuela",
  },
  {
    slug: "vietnam",
    iso2: "VN",
    iso3: "VNM",
    cldr: "Vietnam",
  },
  {
    slug: "yemen",
    iso2: "YE",
    iso3: "YEM",
    cldr: "Yemen",
  },
  {
    slug: "zambia",
    iso2: "ZM",
    iso3: "ZMB",
    cldr: "Zambia",
  },
  {
    slug: "zimbabwe",
    iso2: "ZW",
    iso3: "ZWE",
    cldr: "Zimbabwe",
  },
]
