import enumsOpportunityStatuses from "../enums/opportunityStatuses.js"

const optionsOpportunityStatuses =
  enumsOpportunityStatuses.map((status) => {
    return {
      value: status.id,
      label: status.title,
    }
  })

export default optionsOpportunityStatuses
