<script setup>
import {useAppStore} from "../stores/app.js"
import {useUserStore} from "../stores/user.js"
import {storeToRefs} from "pinia"
import {watch, ref, nextTick, computed, onMounted} from "vue"
import {OneSwitch, OneViewAdmin, OneButton} from "@jirizavadil/framework.one/ui"
import Logo from "../assets/img/logo/atlaseek - vector rgb - primary colored.svg?url"
import LogoWhite from "../assets/img/logo/atlaseek - vector rgb - primary white.svg?url"
import LogoIcon from "../assets/img/logo/atlaseek - vector rgb - icon colored.svg?url"
import LogoIconWhite from "../assets/img/logo/atlaseek - vector rgb - icon white.svg?url"
import {useRoute, useRouter} from "vue-router"
// import {useAuth} from "@/composables/auth"
import ColorSchemeToggle from "../components/ColorSchemeToggle.vue"
import {initAtlaseek} from "@atlaseek/common"

const route = useRoute()
const router = useRouter()

// const auth = useAuth()
// const {allowed, logout} = auth

const appStore = useAppStore()
const userStore = useUserStore()

const {app} = storeToRefs(appStore)
const {user, user: userAuth, userLoaded} = storeToRefs(userStore)
// const loaded = computed(
//   () =>
//     userLoaded.value &&
//     siteLoaded.value &&
//     ((app.value.orgId && orgLoaded.value) || !app.value.orgId) &&
//     ((app.value.spaceId && orgSpacesLoaded.value) || !app.value.spaceId) &&
//     ((app.value.environmentId && orgEnvironmentsLoaded.value) || !app.value.environmentId)
// )

const menuItems = computed(() => [
  {
    title: "dashboard",
    icon: "Home",
    route: {name: "DashboardView"},
    views: ["DashboardView"],
  },
  {
    title: "users",
    icon: "Users",
    route: {name: "UsersView"},
    views: ["UsersView", "UsersDetailView", "UsersAddView"],
  },
  {
    title: "organizations",
    icon: "Building2",
    route: {name: "OrgsView"},
    views: ["OrgsView", "OrgsDetailView", "OrgsAddView"],
  },
  {
    title: "opportunities",
    icon: "DollarSign",
    route: {name: "OpportunitiesView"},
    views: ["OpportunitiesView", "OpportunitiesDetailView"],
  },
  {
    title: "applications",
    icon: "Inbox",
    route: {name: "ApplicationsView"},
    views: ["ApplicationsView", "ApplicationsDetailView", "ApplicationsAddView"],
  },
  {
    title: "collaborations",
    icon: "Handshake",
    route: {name: "CollaborationsView"},
    views: ["CollaborationsView", "CollaborationsDetailView"],
  },
  {
    title: "messaging",
    icon: "MessagesSquare",
    route: {name: "MessagingView"},
    views: ["MessagingView", "MessagingConversationView"],
  },
  {
    title: "profiles",
    icon: "CircleUserRound",
    route: {name: "ProfilesView"},
    views: [
      "ProfilesView",
      "ProfilesAddView",
      "ProfilesDetailMainView",
      "ProfilesDetailContentsView",
      "ProfilesDetailContentsDetailView",
      "ProfilesDetailContentsAddView",
      "ProfilesDetailContentsMetricsSnapshotsView",
      "ProfilesDetailContentsMetricsSnapshotsDetailView",
      "ProfilesDetailContentsMetricsSnapshotsAddView",
      "ProfilesDetailPlatformsView",
      "ProfilesDetailPlatformsDetailView",
      "ProfilesDetailPlatformsMetricsSnapshotsView",
      "ProfilesDetailPlatformsMetricsSnapshotsDetailView",
      "ProfilesDetailPlatformsMetricsSnapshotsAddView",
      "ProfilesDetailTravelPlansView",
      "ProfilesDetailTravelPlansDetailView",
      "ProfilesDetailTravelPlansAddView",
    ],
    // doNotShowDirectChildren: true,
    // children: [
    //   {
    //     title: "profile",
    //     icon: "CircleUserRound",
    //     route: {name: "ProfilesDetailView", params: {profileId: route.params.profileId}},
    //     routeBack: {name: "ProfilesView"},
    //     views: [
    //       "ProfilesDetailView",
    //       // "ProfilesDetailMainView",
    //       // "ProfilesDetailPlatformsView"
    //     ],
    //     children: [
    //       {
    //         title: "main",
    //         icon: "CircleUserRound",
    //         route: {name: "ProfilesDetailMainView", params: {profileId: route.params.profileId}},
    //         views: ["ProfilesDetailMainView"],
    //       },
    //       {
    //         title: "platforms",
    //         icon: "Share2",
    //         route: {
    //           name: "ProfilesDetailPlatformsView",
    //           params: {profileId: route.params.profileId},
    //         },
    //         views: ["ProfilesDetailPlatformsView", "ProfilesDetailPlatformsDetailView"],
    //       },
    //     ],
    //   },
    // ],
  },
  {
    title: "profiles [legacy]",
    icon: "CircleUserRound",
    route: {name: "UserProfilesView"},
    views: ["UserProfilesView", "UserProfileView"],
  },
  {
    title: "articles",
    icon: "FileText",
    route: {name: "ArticlesView"},
    views: ["ArticlesView"],
  },
  {
    title: "locations",
    icon: "MapPin",
    route: {name: "LocationsView"},
    views: ["LocationsView"],
  },
  {
    title: "feedback",
    icon: "MessageSquare",
    route: {name: "FeedbackView"},
    views: ["FeedbackView"],
  },
  {
    title: "subscriptions",
    icon: "CreditCard",
    route: {name: "SubscriptionsView"},
    views: ["SubscriptionsView", "SubscriptionView"],
  },
  {
    title: "plans",
    icon: "CreditCard",
    route: {name: "PlansView"},
    views: ["PlansView", "PlansDetailView", "PlansAddView"],
  },
  {
    title: "roles",
    icon: "PersonStanding",
    route: {name: "RolesView"},
    views: ["RolesView", "RolesDetailView", "RolesAddView"],
  },
  {
    title: "dictionaries",
    icon: "BookOpen",
    route: {name: "EnumsInterestsView"},
    routeBack: {name: "DashboardView"},
    views: [
      "DictionariesView",
      // "EnumsInterestsView",
      // "EnumsInterestsDetailView",
      // "EnumsInterestsAddView",
      // "EnumsSkillsView",
      // "EnumsSkillsDetailView",
      // "EnumsSkillsAddView",
      // "EnumsServiceTypesView",
      // "EnumsServiceTypesDetailView",
      // "EnumsServiceTypesAddView",
      // "EnumsPartnershipModelsView",
      // "EnumsPartnershipModelsDetailView",
      // "EnumsPartnershipModelsAddView",
      // "EnumsAttributesView",
      // "EnumsAttributesDetailView",
      // "EnumsAttributesAddView",
      // "EnumsChannelsView",
      // "EnumsCountriesView",
      // "EnumsEntityTypesView",
      // "EnumsLanguagesView",
      // "EnumsPermissionsView",
      // "EnumsPlatformsView",
      // "EnumsQuotasView",
      // "EnumsScopesView",
      // "EnumsAgeGroupsView",
      // "EnumsAudienceSizesView",
    ],
    children: [
      {
        title: "interests",
        icon: "BookOpen",
        route: {name: "EnumsInterestsView"},
        views: ["EnumsInterestsView", "EnumsInterestsDetailView", "EnumsInterestsAddView"],
      },
      {
        title: "skills",
        icon: "BookOpen",
        route: {name: "EnumsSkillsView"},
        views: ["EnumsSkillsView", "EnumsSkillsDetailView", "EnumsSkillsAddView"],
      },
      {
        title: "service types",
        icon: "BookOpen",
        route: {name: "EnumsServiceTypesView"},
        views: ["EnumsServiceTypesView", "EnumsServiceTypesDetailView", "EnumsServiceTypesAddView"],
      },
      {
        title: "partnership models",
        icon: "BookOpen",
        route: {name: "EnumsPartnershipModelsView"},
        views: [
          "EnumsPartnershipModelsView",
          "EnumsPartnershipModelsDetailView",
          "EnumsPartnershipModelsAddView",
        ],
      },
      {
        title: "partnership durations",
        icon: "BookOpen",
        route: {name: "EnumsPartnershipDurationsView"},
        views: [
          "EnumsPartnershipDurationsView",
          "EnumsPartnershipDurationsDetailView",
          "EnumsPartnershipDurationsAddView",
        ],
      },
      {
        title: "attributes",
        icon: "BookOpen",
        route: {name: "EnumsAttributesView"},
        views: ["EnumsAttributesView", "EnumsAttributesDetailView", "EnumsAttributesAddView"],
      },
      {
        title: "channels",
        icon: "Box",
        route: {name: "EnumsChannelsView"},
        views: ["EnumsChannelsView"],
      },
      {
        title: "countries",
        icon: "Box",
        route: {name: "EnumsCountriesView"},
        views: ["EnumsCountriesView"],
      },
      {
        title: "entity types",
        icon: "Box",
        route: {name: "EnumsEntityTypesView"},
        views: ["EnumsEntityTypesView"],
      },
      {
        title: "languages",
        icon: "Box",
        route: {name: "EnumsLanguagesView"},
        views: ["EnumsLanguagesView"],
      },
      {
        title: "permissions",
        icon: "Box",
        route: {name: "EnumsPermissionsView"},
        views: ["EnumsPermissionsView"],
      },
      {
        title: "platforms",
        icon: "Box",
        route: {name: "EnumsPlatformsView"},
        views: ["EnumsPlatformsView"],
      },
      {
        title: "quotas",
        icon: "Box",
        route: {name: "EnumsQuotasView"},
        views: ["EnumsQuotasView"],
      },
      {
        title: "scopes",
        icon: "Box",
        route: {name: "EnumsScopesView"},
        views: ["EnumsScopesView"],
      },
      {
        title: "age groups",
        icon: "Box",
        route: {name: "EnumsAgeGroupsView"},
        views: ["EnumsAgeGroupsView"],
      },
      {
        title: "audience sizes",
        icon: "Box",
        route: {name: "EnumsAudienceSizesView"},
        views: ["EnumsAudienceSizesView"],
      },
    ],
  },
  {
    title: "resend",
    icon: "Mail",
    route: {name: "ResendAudiencesView"},
    routeBack: {name: "DashboardView"},
    views: ["ResendView"],
    children: [
      {
        title: "audiences",
        icon: "CircleUserRound",
        route: {name: "ResendAudiencesView"},
        views: ["ResendAudiencesView", "ResendAudiencesAddView", "ResendAudiencesDetailView"],
      },
    ],
  },

  // {
  //   title: "booking",
  //   icon: "CalendarCheck",
  //   route: {name: "BookingReservationsView"},
  //   views: [
  //     "BookingView",
  //     "BookingReservationsView",
  //     "BookingReservationsAddView",
  //     "BookingReservationsDetailView",
  //   ],

  //   children: [
  //     {
  //       title: "reservations",
  //       icon: "CalendarCheck",
  //       route: {name: "BookingReservationsView"},
  //       views: [
  //         "BookingReservationsView",
  //         "BookingReservationsAddView",
  //         "BookingReservationsDetailView",
  //       ],
  //     },
  //   ],
  // },
])
const sidebarState = ref("expanded")

function findRouteByName(name, items, parent = null) {
  for (const item of items) {
    // if (item.route?.name === name) {
    if (item.views.includes(name)) {
      return {item, parent}
    }
    if (item.children) {
      const result = findRouteByName(name, item.children, item)
      if (result) {
        return result
      }
    }
  }
  return null
}

const currentMenuItem = computed(() => findRouteByName(route.name, menuItems.value))

const sidebarMenuItems = computed(() => {
  const items = []

  // const currentMenuItem = menuItems.value.find((item) => {
  //   // return item.views.includes(route.name)
  //   return item.route.name === route.name
  // })

  const currentMenuItemHasChildren = !!currentMenuItem.value?.children?.length

  let renderMenuItem = currentMenuItem.value?.item || currentMenuItem.value?.parent

  if (!currentMenuItemHasChildren && currentMenuItem.value?.parent) {
    renderMenuItem = currentMenuItem.value.parent
  }

  const parentRoute = currentMenuItem.value?.parent

  const doNotShowDirectChildren = renderMenuItem?.doNotShowDirectChildren
  let renderChildren = renderMenuItem?.children?.length > 0

  if (doNotShowDirectChildren) {
    if (currentMenuItem.value.parent) {
      renderMenuItem = currentMenuItem.value.parent
    }
    // if no parent -> render root
    else {
      renderChildren = false
    }
  }

  console.log({
    currentMenuItem: currentMenuItem.value,
    currentMenuItemHasChildren,
    currentRoute: route.name,
    parentRoute,
    doNotShowDirectChildren,
    renderMenuItem,
    renderChildren,
  })

  // sub-routes
  if (renderChildren) {
    // back
    items.push({
      title: "back",
      icon: "ArrowLeft",
      route: {name: parentRoute?.routeBack?.name},
    })
    renderMenuItem.children.forEach((child) => {
      // console.log({child})
      const routeParamsAreNotUndefined = Object.values(child.route.params || {}).every(
        (param) => param !== undefined
      )
      const menuItem = {
        ...child,
        // dont include route when undefined
        route: routeParamsAreNotUndefined ? child.route : {},
        active: child.views.includes(route.name),
      }

      items.push(menuItem)
    })
  }
  // root
  else {
    menuItems.value.map((item) => {
      const menuItem = {
        ...item,
        // dont include route when undefined
        route: item.route || {},
        active: item.views.includes(route.name),
      }

      items.push(menuItem)
    })
  }

  // console.log({items})
  return items
})

const sidebarUser = computed(() => {
  return {
    title: user.value.name || "thy gracious name",
    subtitle: userAuth.value.email,
    imageURL: userAuth.value.photoURL,
  }
})

function onToggleTestMode(v) {
  app.value.testMode = v
  localStorage.setItem("testMode", app.value.testMode)
  window.location.reload() // reloading the current pageview APIs would make this better and not blink, but quite an effort
}
</script>

<template>
  <OneViewAdmin
    :sidebarShowProjectSelector="false"
    :sidebarMenuItems
    :sidebarUser
    :mainScrollable="![].includes($route.name)"
    :mainPadding="false"
    @clickResizeHandle="sidebarState = $event"
  >
    <template #logo>
      <div class="pb-4 pt-2" v-show="sidebarState === 'expanded'">
        <img :src="Logo" class="h-auto w-40 dark:hidden" />
        <img :src="LogoWhite" class="hidden h-auto w-40 opacity-50 dark:block" />
      </div>

      <div class="pb-6 pt-2" v-show="sidebarState === 'collapsed'">
        <img :src="LogoIcon" class="mx-auto h-auto w-4 dark:hidden" />
        <img :src="LogoIconWhite" class="mx-auto hidden h-auto w-4 opacity-50 dark:block" />
      </div>
    </template>

    <template #userPopup>
      <div class="p-0">
        <!-- mode -->
        <ColorSchemeToggle class="m-2" />

        <!-- testmode -->
        <div
          class="mx-2 rounded-lg border border-neutral-150 bg-neutral-50 px-2 py-2 dark:border-neutral-900 dark:bg-neutral-950"
        >
          <OneSwitch
            :modelValue="app.testMode"
            labelEnd="test mode"
            :size="6"
            @update:modelValue="onToggleTestMode"
          />
        </div>

        <!-- profile -->
        <OneButton
          label="my profile"
          variant="primary"
          kind="naked"
          rounded="xl"
          align="start"
          fullWidth
          @action="router.push({name: 'UserView'})"
        />

        <!-- sign out -->
        <OneButton
          label="sign out"
          variant="error"
          kind="naked"
          rounded="xl"
          align="start"
          fullWidth
          @action="logout"
        />
      </div>
    </template>

    <template #main>
      <div class="px-4 pb-4">
        <RouterView />
      </div>
    </template>
  </OneViewAdmin>
</template>
