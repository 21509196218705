import {getConfig} from "./init.js"
import axios from "axios" // TODO: replace with fetch when node v21+ is released for firebase functions

const baseUrl = import.meta.env?.VITE_URL_API || process.env.ATLASEEK_API_URL
// console.log({baseUrl})

export async function request({
  endpoint,
  method = "POST",
  data = null,
  opts = {},
}) {
  const config = getConfig()

  let apiKey
  if (config.firebaseUser) {
    // the firebase auth token refreshes often, so i have to get it each time
    apiKey = await config.firebaseUser.getIdToken()
  } else {
    apiKey = config.apiKey
  }

  let url = `${baseUrl}/${endpoint}`
  // const options = {
  //   method,
  //   headers: {
  //     "Content-Type": "application/json",
  //     ...(apiKey ? {Authorization: `Bearer ${apiKey}`} : {}),
  //   },
  // }

  // if (data) {
  //   // GET
  //   if (["GET", "DELETE"].includes(method)) {
  //     const params = new URLSearchParams(data).toString()
  //     url = `${url}?${params}`
  //   }
  //   // POST, PATCH, PUT
  //   else if (["POST", "PATCH", "PUT"].includes(method)) {
  //     options.body = JSON.stringify(data)
  //   }
  // }

  // temporary for axios until replaced by fetch
  const optionsAxios = {
    method,
    url,
    headers: {
      "X-Channel": config.appId,
      "X-Scope-Org": config.scope.orgId,
      "X-TestMode": localStorage.testMode || false,
      "Content-Type": "application/json",
      ...(apiKey ? {Authorization: `Bearer ${apiKey}`} : {}),
    },
    validateStatus: function (status) {
      // Never reject the promise, treat all responses as success - this is same behavior as fetch
      return true // Always resolves the promise
    },
  }

  // temoporary for axios until replaced by fetch
  if (data) {
    if (["GET", "DELETE"].includes(method)) {
      // Use params for GET and DELETE requests
      optionsAxios.params = data
    } else if (["POST", "PATCH", "PUT"].includes(method)) {
      // Use data for POST, PATCH, PUT requests
      optionsAxios.data = data
    }
  }

  try {
    // const response = await fetch(url, options)
    let response = await axios(url, optionsAxios)

    console.log("response", {response})

    // if the token is revoked, force refresh it and retry the request
    if (
      response.status === 403 &&
      response?.data?.statusCode === "auth/id-token-revoked"
    ) {
      console.log("refreshing token and retrying request")
      // force refresh token
      const newToken = await config.firebaseUser.getIdToken(true)
      optionsAxios.headers.Authorization = `Bearer ${newToken}`
      response = await axios(url, optionsAxios)

      console.log("second response", {response})
      // second response 403 -> logout
      if (
        response.status === 403 &&
        response?.data?.statusCode === "auth/id-token-revoked"
      ) {
        // Conditional import for frontend (this can't be at top level, because that's not supported in browsers)
        const auth =
          typeof window !== "undefined"
            ? (await import("@/firebase")).auth
            : null

        // logout only if auth is available (frontend)
        if (auth) {
          console.log("logging out", auth)
          await signOut(auth)
        }
      }
    }

    // code
    const status = response.status

    // const contentType = response.headers.get("content-type")
    // const data = contentType?.includes("application/json")
    //   ? await response.json()
    //   : await response.text()
    const data = response.data

    const result = {
      success: status >= 200 && status < 300,
      error: status >= 400,
      // response: {
      //   status,
      // },
      statusCode: status,
      response: data,
    }

    // convenience function to send app notifications automatically (from ./stores/app.js)

    let notifyAutomatically = true
    if (opts.notify === false) {
      notifyAutomatically = false
    }
    if (opts.notifySuccess === false && data.status === "success") {
      notifyAutomatically = false
    }

    console.log("🔷", {endpoint, notifyAutomatically, optionsAxios})

    if (config.appNotification && notifyAutomatically) {
      config.appNotification({
        title: data.statusMessage || data.statusCode || "unknown error",
        type: data.status || "error",
      })
    }

    console.log("🔶", result)

    return result
  } catch (error) {
    // console.error(error)
    // return {error}
  }
}
