export default [
  {
    id: "male",
    title: "male",
  },
  {
    id: "female",
    title: "female",
  },
  {
    id: "group",
    title: "group",
  }
]
