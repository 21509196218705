import MainView from "../views/MainView.vue"
import AuthView from "../views/AuthView.vue"

export const routes = [
  {
    path: "/",
    name: "MainView",
    component: MainView,
    children: [
      {
        path: "dashboard",
        name: "DashboardView",
        component: () => import("../views/DashboardView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/users",
        name: "UsersView",
        component: () => import("../views/UsersView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "add",
            name: "UsersAddView",
            component: () => import("../views/UsersDetailView.vue"),
            meta: {
              requiresAuth: true,
              title: "new user",
              routeIdParam: "userId", // required for redirect in form.js
            },
          },
          {
            path: ":userId",
            name: "UsersDetailView",
            component: () => import("../views/UsersDetailView.vue"),
            meta: {
              requiresAuth: true,
              title: "user",
              routeIdParam: "userId",
            },
          },
        ],
      },
      {
        path: "/profiles",
        name: "ProfilesView",
        component: () => import("../views/ProfilesView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "add",
            name: "ProfilesAddView",
            component: () => import("../views/ProfilesDetailMainView.vue"),
            meta: {
              requiresAuth: true,
              title: "new profile",
              routeIdParam: "profileId", // required for redirect in form.js
            },
          },
          {
            path: ":profileId",
            name: "ProfilesDetailView",
            component: () => import("../views/ProfilesDetailView.vue"),
            meta: {
              requiresAuth: true,
              title: "profile",
              routeIdParam: "profileId",
            },
            children: [
              {
                path: "main",
                name: "ProfilesDetailMainView",
                component: () => import("../views/ProfilesDetailMainView.vue"),
                meta: {
                  requiresAuth: true,
                  routeIdParam: "profileId",
                },
              },
              {
                path: "platforms",
                name: "ProfilesDetailPlatformsView",
                component: () => import("../views/ProfilesDetailPlatformsView.vue"),
                meta: {
                  requiresAuth: true,
                  routeIdParam: "platformId",
                },
                children: [
                  {
                    path: "add",
                    name: "ProfilesDetailPlatformsAddView",
                    component: () => import("../views/ProfilesDetailPlatformsDetailView.vue"),
                    meta: {
                      requiresAuth: true,
                      title: "new platform",
                      routeIdParam: "platformId",
                    },
                  },
                  {
                    path: ":platformId",
                    name: "ProfilesDetailPlatformsDetailView",
                    component: () => import("../views/ProfilesDetailPlatformsDetailView.vue"),
                    meta: {
                      requiresAuth: true,
                      routeIdParam: "platformId",
                    },
                    children: [
                      {
                        path: "metrics-snapshots",
                        name: "ProfilesDetailPlatformsMetricsSnapshotsView",
                        component: () =>
                          import("../views/ProfilesDetailPlatformsMetricsSnapshotsView.vue"),
                        meta: {
                          requiresAuth: true,
                          routeIdParam: "snapshotId",
                        },
                        children: [
                          {
                            path: "add",
                            name: "ProfilesDetailPlatformsMetricsSnapshotsAddView",
                            component: () =>
                              import(
                                "../views/ProfilesDetailPlatformsMetricsSnapshotsDetailView.vue"
                              ),
                            meta: {
                              requiresAuth: true,
                              title: "new metrics snapshot",
                              routeIdParam: "snapshotId",
                            },
                          },
                          {
                            path: ":snapshotId",
                            name: "ProfilesDetailPlatformsMetricsSnapshotsDetailView",
                            component: () =>
                              import(
                                "../views/ProfilesDetailPlatformsMetricsSnapshotsDetailView.vue"
                              ),
                            meta: {
                              requiresAuth: true,
                              routeIdParam: "snapshotId",
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: "contents",
                name: "ProfilesDetailContentsView",
                component: () => import("../views/ProfilesDetailContentsView.vue"),
                meta: {
                  requiresAuth: true,
                  routeIdParam: "contentId",
                },
                children: [
                  {
                    path: "add",
                    name: "ProfilesDetailContentsAddView",
                    component: () => import("../views/ProfilesDetailContentsDetailView.vue"),
                    meta: {
                      requiresAuth: true,
                      title: "new content",
                      routeIdParam: "contentId",
                    },
                  },
                  {
                    path: ":contentId",
                    name: "ProfilesDetailContentsDetailView",
                    component: () => import("../views/ProfilesDetailContentsDetailView.vue"),
                    meta: {
                      requiresAuth: true,
                      routeIdParam: "contentId",
                    },
                    children: [
                      {
                        path: "metrics-snapshots",
                        name: "ProfilesDetailContentsMetricsSnapshotsView",
                        component: () =>
                          import("../views/ProfilesDetailContentsMetricsSnapshotsView.vue"),
                        meta: {
                          requiresAuth: true,
                          routeIdParam: "snapshotId",
                        },
                        children: [
                          {
                            path: "add",
                            name: "ProfilesDetailContentsMetricsSnapshotsAddView",
                            component: () =>
                              import(
                                "../views/ProfilesDetailContentsMetricsSnapshotsDetailView.vue"
                              ),
                            meta: {
                              requiresAuth: true,
                              title: "new metrics snapshot",
                              routeIdParam: "snapshotId",
                            },
                          },
                          {
                            path: ":snapshotId",
                            name: "ProfilesDetailContentsMetricsSnapshotsDetailView",
                            component: () =>
                              import(
                                "../views/ProfilesDetailContentsMetricsSnapshotsDetailView.vue"
                              ),
                            meta: {
                              requiresAuth: true,
                              routeIdParam: "snapshotId",
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: "travel-plans",
                name: "ProfilesDetailTravelPlansView",
                component: () => import("../views/ProfilesDetailTravelPlansView.vue"),
                meta: {
                  requiresAuth: true,
                },
                children: [
                  {
                    path: "add",
                    name: "ProfilesDetailTravelPlansAddView",
                    component: () => import("../views/ProfilesDetailTravelPlansDetailView.vue"),
                    meta: {
                      requiresAuth: true,
                      title: "new travel plan",
                      routeIdParam: "travelPlanId",
                    },
                  },
                  {
                    path: ":travelPlanId",
                    name: "ProfilesDetailTravelPlansDetailView",
                    component: () => import("../views/ProfilesDetailTravelPlansDetailView.vue"),
                    meta: {
                      requiresAuth: true,
                      routeIdParam: "travelPlanId",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/user-profiles",
        name: "UserProfilesView",
        component: () => import("../views/UserProfilesView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: ":userId",
            name: "UserProfileView",
            component: () => import("../views/UserProfileView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "/articles",
        name: "ArticlesView",
        component: () => import("../views/ArticlesView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: ":articleId",
            name: "ArticleView",
            component: () => import("../views/ArticleView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "/locations",
        name: "LocationsView",
        component: () => import("../views/LocationsView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: ":locationId",
            name: "LocationView",
            component: () => import("../views/LocationView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "/feedback",
        name: "FeedbackView",
        component: () => import("../views/FeedbackView.vue"),
        meta: {
          requiresAuth: true,
        },
        // children: [
        //   {
        //     path: ":locationId",
        //     name: "LocationView",
        //     component: () => import("../views/LocationView.vue"),
        //     meta: {
        //       requiresAuth: true,
        //     },
        //   },
        // ],
      },
      {
        path: "/subscriptions",
        name: "SubscriptionsView",
        component: () => import("../views/SubscriptionsView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: ":subscriptionId",
            name: "SubscriptionView",
            component: () => import("../views/SubscriptionView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "/notifications",
        name: "NotificationsView",
        component: () => import("../views/NotificationsView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      // hub
      // dictionaries (enums)
      {
        path: "/dictionaries",
        name: "DictionariesView",
        component: () => import("../views/DictionariesView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "interests",
            name: "EnumsInterestsView",
            component: () => import("../views/EnumsInterestsView.vue"),
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: "add",
                name: "EnumsInterestsAddView",
                component: () => import("../views/EnumsInterestsView.vue"),
                meta: {
                  requiresAuth: true,
                  title: "new interest",
                },
              },
              {
                path: ":id",
                name: "EnumsInterestsDetailView",
                component: () => import("../views/EnumsInterestsView.vue"),
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
          {
            path: "skills",
            name: "EnumsSkillsView",
            component: () => import("../views/EnumsSkillsView.vue"),
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: "add",
                name: "EnumsSkillsAddView",
                component: () => import("../views/EnumsSkillsView.vue"),
                meta: {
                  requiresAuth: true,
                  title: "new skill",
                },
              },
              {
                path: ":id",
                name: "EnumsSkillsDetailView",
                component: () => import("../views/EnumsSkillsView.vue"),
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
          {
            path: "service-types",
            name: "EnumsServiceTypesView",
            component: () => import("../views/EnumsServiceTypesView.vue"),
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: "add",
                name: "EnumsServiceTypesAddView",
                component: () => import("../views/EnumsServiceTypesView.vue"),
                meta: {
                  requiresAuth: true,
                  title: "new service type",
                },
              },
              {
                path: ":id",
                name: "EnumsServiceTypesDetailView",
                component: () => import("../views/EnumsServiceTypesView.vue"),
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
          {
            path: "partnership-models",
            name: "EnumsPartnershipModelsView",
            component: () => import("../views/EnumsPartnershipModelsView.vue"),
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: "add",
                name: "EnumsPartnershipModelsAddView",
                component: () => import("../views/EnumsPartnershipModelsView.vue"),
                meta: {
                  requiresAuth: true,
                  title: "new partnership model",
                },
              },
              {
                path: ":id",
                name: "EnumsPartnershipModelsDetailView",
                component: () => import("../views/EnumsPartnershipModelsView.vue"),
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
          {
            path: "partnership-durations",
            name: "EnumsPartnershipDurationsView",
            component: () => import("../views/EnumsPartnershipDurationsView.vue"),
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: "add",
                name: "EnumsPartnershipDurationsAddView",
                component: () => import("../views/EnumsPartnershipDurationsView.vue"),
                meta: {
                  requiresAuth: true,
                  title: "new partnership duration",
                },
              },
              {
                path: ":id",
                name: "EnumsPartnershipDurationsDetailView",
                component: () => import("../views/EnumsPartnershipDurationsView.vue"),
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
          {
            path: "attributes",
            name: "EnumsAttributesView",
            component: () => import("../views/EnumsAttributesView.vue"),
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: "add",
                name: "EnumsAttributesAddView",
                component: () => import("../views/EnumsAttributesView.vue"),
                meta: {
                  requiresAuth: true,
                  title: "new attribute",
                },
              },
              {
                path: ":id",
                name: "EnumsAttributesDetailView",
                component: () => import("../views/EnumsAttributesView.vue"),
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
          {
            path: "age-groups",
            name: "EnumsAgeGroupsView",
            component: () => import("../views/EnumsHardcodedView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "audience-sizes",
            name: "EnumsAudienceSizesView",
            component: () => import("../views/EnumsHardcodedView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "channels",
            name: "EnumsChannelsView",
            component: () => import("../views/EnumsHardcodedView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "countries",
            name: "EnumsCountriesView",
            component: () => import("../views/EnumsHardcodedView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "entity-types",
            name: "EnumsEntityTypesView",
            component: () => import("../views/EnumsHardcodedView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "languages",
            name: "EnumsLanguagesView",
            component: () => import("../views/EnumsHardcodedView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "permissions",
            name: "EnumsPermissionsView",
            component: () => import("../views/EnumsHardcodedView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "platforms",
            name: "EnumsPlatformsView",
            component: () => import("../views/EnumsHardcodedView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "quotas",
            name: "EnumsQuotasView",
            component: () => import("../views/EnumsHardcodedView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "scopes",
            name: "EnumsScopesView",
            component: () => import("../views/EnumsHardcodedView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      // resend
      {
        path: "/resend",
        name: "ResendView",
        component: () => import("../views/ResendView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "audiences",
            name: "ResendAudiencesView",
            component: () => import("../views/ResendAudiencesView.vue"),
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: "add",
                name: "ResendAudiencesAddView",
                component: () => import("../views/ResendAudiencesDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  title: "new user",
                  routeIdParam: "audienceId", // required for redirect in form.js
                },
              },
              {
                path: ":audienceId",
                name: "ResendAudiencesDetailView",
                component: () => import("../views/ResendAudiencesDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  title: "user",
                  routeIdParam: "audienceId",
                },
              },
            ],
          },
        ],
      },
      {
        path: "/plans",
        name: "PlansView",
        component: () => import("../views/PlansView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "add",
            name: "PlansAddView",
            component: () => import("../views/PlansView.vue"),
            meta: {
              requiresAuth: true,
              title: "new plan",
            },
          },
          {
            path: ":id",
            name: "PlansDetailView",
            component: () => import("../views/PlansView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "/roles",
        name: "RolesView",
        component: () => import("../views/RolesView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "add",
            name: "RolesAddView",
            component: () => import("../views/RolesView.vue"),
            meta: {
              requiresAuth: true,
              title: "new role",
            },
          },
          {
            path: ":id",
            name: "RolesDetailView",
            component: () => import("../views/RolesView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "/orgs",
        name: "OrgsView",
        component: () => import("../views/OrgsView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "add",
            name: "OrgsAddView",
            component: () => import("../views/OrgsDetailView.vue"),
            meta: {
              requiresAuth: true,
              title: "new organization",
              routeIdParam: "orgId", // required for redirect in form.js
            },
          },
          {
            path: ":orgId",
            name: "OrgsDetailView",
            component: () => import("../views/OrgsDetailView.vue"),
            meta: {
              requiresAuth: true,
              title: "organization",
              routeIdParam: "orgId",
            },
            // children: [
            //   {
            //     path: "platforms/add",
            //     name: "OrgsDetailPlatformAddView",
            //     component: () => import("../views/OrgsDetailPlatformView.vue"),
            //     meta: {
            //       requiresAuth: true,
            //       title: "new platform",
            //     },
            //   },
            //   {
            //     path: "platforms/:platformId",
            //     name: "OrgsDetailPlatformDetailView",
            //     component: () => import("../views/OrgsDetailPlatformView.vue"),
            //     meta: {
            //       requiresAuth: true,
            //       routeIdParam: "platformId",
            //     },
            //   },
            // ],
          },
        ],
      },
      {
        path: "/opportunities",
        name: "OpportunitiesView",
        component: () => import("../views/OpportunitiesView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "add",
            name: "OpportunitiesAddView",
            component: () => import("../views/OpportunitiesDetailView.vue"),
            meta: {
              requiresAuth: true,
              title: "new opportunity",
            },
          },
          {
            path: ":opportunityId",
            name: "OpportunitiesDetailView",
            component: () => import("../views/OpportunitiesDetailView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "/applications",
        name: "ApplicationsView",
        component: () => import("../views/ApplicationsView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/collaborations",
        name: "CollaborationsView",
        component: () => import("../views/CollaborationsView.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/messaging",
        name: "MessagingView",
        component: () => import("../views/MessagingView.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "conversation/:conversationId",
            name: "MessagingConversationView",
            component: () => import("../views/MessagingConversationView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    name: "AuthView",
    component: AuthView,
  },
]
