<script setup>
import {ref, computed} from "vue"

const props = defineProps({
  size: {
    type: String,
    default: "m",
  },
  center: {
    type: Boolean,
    default: true,
  },
  color: {
    type: String,
    default: "default",
  },
})
const progress = ref(60)

const radius = computed(() => {
  if (props.size == "s") return 12
  if (props.size == "m") return 18
  if (props.size == "l") return 38
  return 18
})
const stroke = computed(() => {
  if (props.size == "s") return 2
  if (props.size == "m") return 3
  if (props.size == "l") return 5
  return 3
})
const strokeDashoffset = computed(() => {
  return circumference.value - (progress.value / 100) * circumference.value
})
const normalizedRadius = computed(() => {
  return radius.value - stroke.value * 2
})
const circumference = computed(() => {
  return normalizedRadius.value * 2 * Math.PI
})
</script>

<template>
  <div class="one-loading" :class="{center: center}">
    <svg class="one-loading-img" :class="[`size-${size}`, `color-${color}`]">
      <circle
        fill="transparent"
        :stroke-dasharray="circumference + ' ' + circumference"
        :style="{strokeDashoffset}"
        :stroke-width="stroke"
        stroke-linecap="round"
        :r="normalizedRadius"
        :cx="radius"
        :cy="radius"
      />
    </svg>
  </div>
</template>

<style scoped lang="less">
@keyframes spin {
  100% {
    transform: rotate(360deg) translateZ(0);
  }
}

.one-loading {
  &.center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  svg.one-loading-img {
    transition: stroke-dashoffset 0.35s;
    transform-origin: 50% 50%;
    animation: spin 1s linear infinite;

    &.color-default * {
      stroke: #acacac;
    }

    &.color-white * {
      stroke: #fff;
    }

    &.color-dark * {
      stroke: #000;
    }

    &.size-s {
      width: 24px;
      height: 24px;
    }

    &.size-m {
      width: 36px;
      height: 36px;
    }

    &.size-l {
      width: 76px;
      height: 76px;
    }
  }
}
</style>
