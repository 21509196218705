import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore, functions} from "@/firebase/index.js"
import {doc, serverTimestamp, updateDoc, deleteDoc} from "firebase/firestore"
import {httpsCallable} from "firebase/functions"
const usersManageFn = httpsCallable(functions, "calls-usersManage")

export const useUserStore = defineStore("user", () => {
  // state
  const user = ref({})
  const auth = ref({})
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const userLoaded = computed(() => status.value == "loaded")
  const userId = computed(() => user.value.id)
  const userConfig = computed(() => user.value.config)
  const userLang = computed(() => user.value.config?.lang)
  const userPinnedSites = computed(() => user.value.config?.pinned?.sites || [])
  const userSelectedSiteId = computed(() => user.value.config?.selectedSiteId)

  // actions
  async function bind(args) {
    const uid = args.userId
    console.log("binding user:", uid)
    const docRef = doc(firestore, `users/${uid}`)
    return await this.attach("user", docRef)
  }

  async function unbind() {
    return await this.detach()
  }

  async function update({userId, data}) {
    const docRef = doc(firestore, `users/${userId}`)
    const updateData = {
      ...data,
      "meta.updated": serverTimestamp(),
    }
    console.log("updating user with data:", data)
    return await updateDoc(docRef, updateData)
  }

  async function remove({userId}) {
    const docRef = doc(firestore, `users/${userId}`)
    const deleteInFirestore = deleteDoc(docRef)
    const deleteInAuth = usersManageFn({action: "delete", userId})
    return await Promise.all([deleteInFirestore, deleteInAuth])
  }

  return {
    // state
    user,
    auth,
    status,
    unsubscribe,

    // getters
    userLoaded,
    userId,
    userConfig,
    userLang,
    userPinnedSites,
    userSelectedSiteId,

    // actions
    bind,
    unbind,
    update,
    remove,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
