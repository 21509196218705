import enumsEntityTypes from "../enums/entityTypes.js"

const optionsEntityTypes = enumsEntityTypes
  .filter((entity) => entity.scope == "org")
  .map((entityType) => {
    return {
      value: entityType.id,
      label: entityType.title,
    }
  })

export default optionsEntityTypes
