export default [
  {
    id: "articlesRead",
    title: "articles read",
    description: "allows user to read articles",
    channel: "explore",
    scope: "user",
  },
  {
    id: "articlesCreate",
    title: "articles create",
    description: "allows user to create articles",
    channel: "explore",
    scope: "user",
  },
  {
    id: "articlesPublish",
    title: "articles publish",
    description: "allows user to publish articles",
    channel: "explore",
    scope: "user",
  },
  {
    id: "agenciesMessagesSend",
    title: "agencies messages send",
    description: "allows org to send messages to agencies",
    channel: "hub",
    scope: "org",
  },
  {
    id: "agenciesOffersApply",
    title: "agencies offers apply",
    description: "allows org to apply to agencies offers",
    channel: "hub",
    scope: "org",
  },
  {
    id: "agenciesOffersRead",
    title: "agencies offers read",
    description: "allows org to read agencies offers",
    channel: "hub",
    scope: "org",
  },
  {
    id: "agenciesProfilesRead",
    title: "agencies profiles read",
    description: "allows org to read agencies profiles",
    channel: "hub",
    scope: "org",
  },
  {
    id: "agenciesServicesRead",
    title: "agencies services read",
    description: "allows org to read agencies services",
    channel: "hub",
    scope: "org",
  },
  {
    id: "agenciesServicesRequest",
    title: "agencies services request",
    description: "allows org to request agencies services",
    channel: "hub",
    scope: "org",
  },
  {
    id: "businessesMessagesSend",
    title: "businesses messages send",
    description: "allows org to send messages to businesses",
    channel: "hub",
    scope: "org",
  },
  {
    id: "businessesOffersApply",
    title: "businesses offers apply",
    description: "allows org to apply to businesses offers",
    channel: "hub",
    scope: "org",
  },
  {
    id: "businessesOffersRead",
    title: "businesses offers read",
    description: "allows org to read businesses offers",
    channel: "hub",
    scope: "org",
  },
  {
    id: "businessesProfilesRead",
    title: "businesses profiles read",
    description: "allows org to read businesses profiles",
    channel: "hub",
    scope: "org",
  },
  {
    id: "childOrgsAssignRoles",
    title: "child orgs assign roles",
    description: "allows org to allow assigning users to sublicensed orgs",
    channel: "hub",
    scope: "org",
  },
  {
    id: "childOrgsManage",
    title: "child orgs manage",
    description: "allows org to license management access",
    channel: "hub",
    scope: "org",
  },
  {
    id: "creatorsApplicationsReceive",
    title: "creators applications receive",
    description: "allows org to receive applications from creators",
    channel: "hub",
    scope: "org",
  },
  {
    id: "creatorsMessagesSend",
    title: "creators messages send",
    description: "allows org to send messages to creators",
    channel: "hub",
    scope: "org",
  },
  {
    id: "creatorsProfilesAnonymizedRead",
    title: "creators profiles anonymized read",
    description: "allows org to read creators anonymized profiles",
    channel: "hub",
    scope: "org",
  },
  {
    id: "creatorsProfilesFullRead",
    title: "creators profiles full read",
    description: "allows org to read creators full profiles",
    channel: "hub",
    scope: "org",
  },
  {
    id: "creatorsServicesAnonymizedRead",
    title: "creators services anonymized read",
    description: "allows org to read creators anonymized services",
    channel: "hub",
    scope: "org",
  },
  {
    id: "creatorsServicesFullRead",
    title: "creators services full read",
    description: "allows org to read creators full services",
    channel: "hub",
    scope: "org",
  },
  {
    id: "creatorsServicesRequest",
    title: "creators services request",
    description: "allows org to request creators services",
    channel: "hub",
    scope: "org",
  },
  {
    id: "dashboard",
    title: "dashboard",
    description: "allows org to dashboard access",
    channel: "hub",
    scope: "org",
  },
  {
    id: "multiUserAccess",
    title: "multi user access",
    description: "allows org to multi user access",
    channel: "hub",
    scope: "org",
  },
  {
    id: "offersPost",
    title: "offers post",
    description: "allows org to post offers",
    channel: "hub",
    scope: "org",
  },
  {
    id: "servicesPost",
    title: "services post",
    description: "allows org to post services",
    channel: "hub",
    scope: "org",
  },
  {
    id: "widgetCreate",
    title: "widget create",
    description: "allows org to create widget",
    channel: "hub",
    scope: "org",
  },
]
