import {request} from "../request.js"

export const plansCreate = async (data, opts) => {
  return request({
    endpoint: "plans",
    method: "POST",
    data: {data},
    opts,
  })
}

export const plansGet = async (planId, opts) => {
  return request({
    endpoint: planId ? `plans/${planId}` : "plans",
    method: "GET",
    opts,
  })
}

export const plansUpdate = async (planId, data, opts) => {
  return request({
    endpoint: `plans/${planId}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const plansDelete = async (planId, opts) => {
  return request({
    endpoint: `plans/${planId}`,
    method: "DELETE",
    opts,
  })
}
