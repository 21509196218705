import {createApp} from "vue"
import {router} from "./router/index.js"
import App from "./App.vue"
import {createPinia} from "pinia"
import {attachFirestore} from "./plugins/attachFirestore.js"
import {resetState} from "./plugins/resetState.js"
import globalComponents from "./plugins/globalComponents.js"
import googleMapsApi from "./plugins/googleMapsApi.js"
import "firebaseui/dist/firebaseui.css"
import "./assets/tailwind.css"
import "./assets/firebaseui-override.less"

const pinia = createPinia()
pinia.use(attachFirestore)
pinia.use(resetState)

const app = createApp(App)

app.use(globalComponents)
app.use(pinia)
app.use(router)
app.use(googleMapsApi)
app.mount("#app")
