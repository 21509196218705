export default [
  {
    id: "explorer",
    title: "explorer",
    scope: "user",
  },
  {
    id: "creator",
    title: "creator",
    scope: "user",
  },
  {
    id: "business",
    title: "business",
    scope: "org",
  },
  {
    id: "agency",
    title: "agency",
    scope: "org",
  },
]
