import {createRouter, createWebHistory} from "vue-router"
import {routes} from "./routes"
import {auth} from "@/firebase"
// import {signOut} from "firebase/auth"
// import {useUserStore} from "../stores/user.js"
// const userStore = useUserStore()

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  let user = auth.currentUser //userStore.auth.value

  // console.log(
  //   "beforeEach:",
  //   to,
  //   requiresAuth,
  //   user,
  //   user?.email,
  //   user?.email?.endsWith("@zavadil.pro")
  // )
  // if user is not from @atlaseek.com, just loggoff immediately and go back to auth
  if (
    requiresAuth &&
    !user?.email?.endsWith("@atlaseek.com") &&
    user?.email != "jiri@zavadil.pro" &&
    user?.email != "kristina@melsova.com"
  ) {
    // console.log("signing out")
    // signOut(auth)
    return {name: "AuthView"}
  }

  // router.beforeEach(async (to) => {
  //   const userSignedIn = auth.currentUser
  //   // console.log("router.beforeEach:", to, from, userSignedIn)
  //   if (!userSignedIn && !["AuthView", "AuthVerifyView"].includes(to.name)) {
  //     // console.log("router: not allowed, redirecting to Auth")
  //     return {name: "AuthView"}
  //   }
  // })
})

export {router}
