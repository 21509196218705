import enumsLanguages from "../enums/languages_tiny.js"

const optionsLanguages = enumsLanguages.map((language) => {
  return {
    value: language.iso2,
    label: language.en,
  }
})

export default optionsLanguages
