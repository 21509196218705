export default [
  {
    id: "creatorsMessagesSendMonthly",
    title: "creators messages send monthly",
    description: "number of messages to creators per month [-1 (unlimited) | N]",
    channel: "hub",
    scope: "org",
  },
  {
    id: "creatorsProfilesFullReadsMonthly",
    title: "creators profiles full reads monthly",
    description: "number of uncover creator name and contact per month [-1 (unlimited) | N]",
    channel: "hub",
    scope: "org",
  },
  {
    id: "offersActive",
    title: "offers active",
    description: "number of open offers at one time [-1 (unlimited) | N]",
    channel: "hub",
    scope: "org",
  },
  {
    id: "widgetsActive",
    title: "widgets active",
    description: "number of widgets [-1 (unlimited) | N]",
    channel: "hub",
    scope: "org",
  },
]
