import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {plansGet} from "@atlaseek/common"

export const usePlanStore = defineStore("plan", () => {
  // state
  const plans = ref([])
  const statusPlans = ref("init")

  // getters
  const plansLoaded = computed(() => statusPlans.value == "loaded")
  const plansOptions = computed(() => plans.value.map((p) => ({label: p.name, value: p.id})))
  const plansOptionsExploreUser = computed(() =>
    plans.value
      .filter((p) => p.channel == "explore" && p.scope == "user")
      .map((p) => ({label: p.name, value: p.id}))
  )
  const plansOptionsExploreOrg = computed(() =>
    plans.value
      .filter((p) => p.channel == "explore" && p.scope == "org")
      .map((p) => ({label: p.name, value: p.id}))
  )
  const plansOptionsHubUser = computed(() =>
    plans.value
      .filter((p) => p.channel == "hub" && p.scope == "user")
      .map((p) => ({label: p.name, value: p.id}))
  )
  const plansOptionsHubOrg = computed(() =>
    plans.value
      .filter((p) => p.channel == "hub" && p.scope == "org")
      .map((p) => ({label: p.name, value: p.id}))
  )

  // actions
  async function get() {
    const res = await plansGet(undefined, {
      notifySuccess: false,
    })
    plans.value = res.response.data
  }

  async function clear() {
    plans.value = []
    statusPlans.value = "init"
  }

  return {
    plans,
    statusPlans,
    plansLoaded,
    plansOptions,
    plansOptionsExploreUser,
    plansOptionsExploreOrg,
    plansOptionsHubUser,
    plansOptionsHubOrg,
    get,
    clear,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePlanStore, import.meta.hot))
}
