export default [
  {
    id: "global",
    title: "global",
  },
  {
    id: "explore",
    title: "explore",
  },
  {
    id: "hub",
    title: "hub",
  },
]
