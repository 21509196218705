import enumsPlatformMetricsSnapshotVerificationMethods from "../enums/platformMetricsSnapshotVerificationMethods.js"

const optionsPlatformMetricsSnapshotVerificationMethods =
  enumsPlatformMetricsSnapshotVerificationMethods.map((method) => {
    return {
      value: method.id,
      label: method.title,
    }
  })

export default optionsPlatformMetricsSnapshotVerificationMethods
