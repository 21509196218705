export default [
  {
    id: "pending",
    title: "pending",
  },
  {
    id: "approved",
    title: "approved",
  },
  {
    id: "rejected",
    title: "rejected",
  },
]
