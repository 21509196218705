<template><div></div></template>

<script setup>
import {useFirebaseMessaging} from "@/composables/firebaseMessaging.js"
import {useUserStore} from "@/stores/user"
import {storeToRefs} from "pinia"

// stores
const userStore = useUserStore()

// getters
const {userId} = storeToRefs(userStore)

const {initMessaging} = useFirebaseMessaging({userId: userId.value})

initMessaging()
</script>
