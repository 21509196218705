import countries_tiny from "./countries_tiny.js";

export default [
  ...countries_tiny,
  {
    slug: "global",
    iso2: null,
    iso3: null,
    cldr: null,
    title: 'Global',
    virtual: true
  },
]