import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {dictionaryGet} from "@atlaseek/common"

export const useDictionaryStore = defineStore("dictionary", () => {
  // state
  const dictionary = ref([])
  const statusDictionary = ref("init")

  // getters
  const dictionaryLoaded = computed(() => statusDictionary.value == "loaded")
  const categories = computed(() => dictionary.value.map((item) => item.categoryId))
  const entriesByCategory = computed(() => {
    const entries = {}
    dictionary.value.forEach((item) => {
      if (!entries[item.categoryId]) {
        entries[item.categoryId] = []
      }
      entries[item.categoryId].push(item)
    })
    return entries
  })
  const entriesByCategoryAsOptions = computed(() => {
    const entries = {}
    dictionary.value.forEach((item) => {
      if (!entries[item.categoryId]) {
        entries[item.categoryId] = []
      }
      entries[item.categoryId].push({value: item.id, label: item.name})
    })
    return entries
  })

  // actions
  async function get() {
    const res = await dictionaryGet(undefined, undefined, {
      include: ["category"],
      notifySuccess: false,
    })
    dictionary.value = res.response.data
  }

  async function clear() {
    dictionary.value = []
    statusDictionary.value = "init"
  }

  return {
    dictionary,
    statusDictionary,
    dictionaryLoaded,
    categories,
    entriesByCategory,
    entriesByCategoryAsOptions,
    get,
    clear,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDictionaryStore, import.meta.hot))
}
