import enumsEntityTypes from "../enums/entityTypes.js"

const optionsEntityTypes = enumsEntityTypes.map((entityType) => {
  return {
    value: entityType.id,
    label: entityType.title,
  }
})

export default optionsEntityTypes
