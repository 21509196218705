import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {rolesGet} from "@atlaseek/common"

export const useRoleStore = defineStore("role", () => {
  // state
  const roles = ref([])
  const statusRoles = ref("init")

  // getters
  const rolesLoaded = computed(() => statusRoles.value == "loaded")
  const rolesOptions = computed(() => roles.value.map((r) => ({label: r.name, value: r.id})))
  const rolesOptionsExploreUser = computed(() =>
    roles.value
      .filter((r) => r.channel == "explore" && r.scope == "user")
      .map((r) => ({label: r.name, value: r.id}))
  )
  const rolesOptionsExploreOrg = computed(() =>
    roles.value
      .filter((r) => r.channel == "explore" && r.scope == "org")
      .map((r) => ({label: r.name, value: r.id}))
  )
  const rolesOptionsHubUser = computed(() =>
    roles.value
      .filter((r) => r.channel == "hub" && r.scope == "user")
      .map((r) => ({label: r.name, value: r.id}))
  )
  const rolesOptionsHubOrg = computed(() =>
    roles.value
      .filter((r) => r.channel == "hub" && r.scope == "org")
      .map((r) => ({label: r.name, value: r.id}))
  )

  // actions
  async function get() {
    const res = await rolesGet(undefined, {
      notifySuccess: false,
    })
    roles.value = res.response.data
  }

  async function clear() {
    roles.value = []
    statusRoles.value = "init"
  }

  return {
    roles,
    statusRoles,
    rolesLoaded,
    rolesOptions,
    rolesOptionsExploreUser,
    rolesOptionsExploreOrg,
    rolesOptionsHubUser,
    rolesOptionsHubOrg,
    get,
    clear,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRoleStore, import.meta.hot))
}
