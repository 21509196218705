<script setup>
import {firebaseui, auth} from "@/firebase"
import Logo from "../assets/img/logo/atlaseek - vector rgb - primary colored.svg"
import {nextTick, onMounted} from "vue"
import {useRouter} from "vue-router"

onMounted(() => {
  nextTick(() => {
    initFirebaseUI()
  })
})

function initFirebaseUI() {
  const router = useRouter()
  let ui = firebaseui.auth.AuthUI.getInstance()
  if (!ui) {
    ui = new firebaseui.auth.AuthUI(auth)
  }
  var uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        console.log("signin result:,", authResult, redirectUrl)
        const user = authResult.user

        if (router && user) {
          router.push({name: "DashboardView"})
        }
        // var credential = authResult.credential
        // var isNewUser = authResult.additionalUserInfo.isNewUser
        // var providerId = authResult.additionalUserInfo.providerId
        // var operationType = authResult.operationType
        // Do something with the returned AuthResult.
        // Return type determines whether we continue the redirect
        // automatically or whether we leave that to developer to handle.
        return false
      },
      signInFailure: function (error) {
        console.error("signin failure:", error)
        // Some unrecoverable error occurred during sign-in.
        // Return a promise when error handling is completed and FirebaseUI
        // will reset, clearing any UI. This commonly occurs for error code
        // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
        // occurs. Check below for more details on this.
        // return handleUIError(error)
      },
      uiShown: function () {
        // The widget is rendered.
        // Hide the loader.
        // document.getElementById("loader").style.display = "none"
      },
    },
    // signInSuccessUrl: "/",
    signInOptions: ["google.com"],
    signinFlow: "popup", // default is 'redirect', but doesn't work in icognito tab
  }
  ui.start("#firebaseui-auth-container", uiConfig)
}
</script>

<template>
  <div class="w-screen h-screen flex justify-center items-center text-center">
    <div class="">
      <Logo class="w-48 h-auto mx-auto" />
      <span class="block mt-4 text-3xl font-bold text-brown-light dark:text-neutral-700">admin</span>
      <section class="mt-16" id="firebaseui-auth-container"></section>
    </div>
  </div>
</template>
