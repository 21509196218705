export default [
  {
    id: "org",
    title: "org",
  },
  {
    id: "user",
    title: "user",
  },
]
