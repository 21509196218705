import enumsCountries from "../enums/countries.js"

const optionsCountries = enumsCountries.map((country) => {
  return {
    value: country.iso3 || country.slug,
    label: country.cldr || country.title,
  }
})

export default optionsCountries
