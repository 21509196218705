export default {
  // updated by user
  bioExplore: null,
  bioHub: null,
  platformMain: null,
  platformsUsed: [],
  platformsAggresgatedMetricsFollowersManual: null,
  links: [], // profile links
  birthday: null, // for age filtering
  gender: null,
  languages: {
    all: [],
    primary: null,
    secondary: null,
    tertiary: null,
  },
  // countriesOperating = "based in" TODO: add to schema and filters
  countriesOperating: {
    all: [],
    primary: null,
    secondary: null,
    tertiary: null,
  },
  interests: [],
  skills: [],
  serviceTypes: [],
  partnershipModels: [],
  attributes: [],
  channels: {
    explore: {
      profileVisibility: {
        url: false, // visible at atlaseek.com/@user1
        listingLanding: false, // prev: publicListing
        listingJoin: false, // prev: miniListing
      },
    },
    hub: {
      profileVerification: {
        verified: false, // manually verified by hub admin
        verifiedByUserId: null,
        verifiedAt: null,
      },
      profileAtlaseekApproved: {
        approved: false, // meets atlaseek standards
        approvedByUserId: null,
        approvedAt: null,
      },
      profileVisibility: {
        url: false, // visible at hub.atlaseek.com/@user1
        listing: false, // visible in hub - users/profiles who apply in business's widget are not visible by default
      },
    },
  },

  // updated by system (triggers)
  pastPartnerships: [],
  articles: [],
  locations: [],
  stats: {
    countries: 0,
    pins: 0,
    followers: 0,
    articles: 0,
  },
}
