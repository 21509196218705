export default [
  {
    id: "instagram",
    title: "instagram",
    url: "https://instagram.com/",
    urlProfilePrefix: "https://instagram.com/",
    hasUsername: true,
    hasUrl: false,
    metrics: {
      daysInMonth: 30,
      engagementRateCalculation:
        "(content interactions / accounts reached) x 100",
    },
  },
  {
    id: "youtube",
    title: "youtube",
    url: "https://youtube.com/",
    urlProfilePrefix: "https://youtube.com/channel/",
    hasUsername: true,
    hasUrl: false,
    metrics: {
      daysInMonth: 28,
      engagementRateCalculation: "((likes + comments + shares) / views) x 100",
    },
  },
  {
    id: "tiktok",
    title: "tiktok",
    url: "https://tiktok.com/",
    urlProfilePrefix: "https://tiktok.com/@",
    hasUsername: true,
    hasUrl: false,
    metrics: {
      daysInMonth: 28,
      engagementRateCalculation: "((likes + comments + shares) / views) x 100",
    },
  },
  {
    id: "atlaseek",
    title: "atlaseek",
    url: "https://atlaseek.com/",
    urlProfilePrefix: "https://atlaseek.com/@",
    hasUsername: true,
    hasUrl: false,
  },
  {
    id: "facebook",
    title: "facebook",
    url: "https://facebook.com/",
    urlProfilePrefix: "https://facebook.com/",
    hasUsername: true,
    hasUrl: false,
    metrics: {
      daysInMonth: 28,
      engagementRateCalculation: "(content interactions / reach) x 100",
    },
  },
  {
    id: "blog",
    title: "blog",
    hasUsername: false,
    hasUrl: true,
  },
  {
    id: "website",
    title: "website",
    hasUsername: false,
    hasUrl: true,
  },
]
