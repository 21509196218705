<script setup>
import {auth} from "@/firebase"
import {onAuthStateChanged} from "firebase/auth"
import {useRoute, useRouter} from "vue-router"
import {useUserStore} from "./stores/user.js"
import {storeToRefs} from "pinia"
import FirebaseMessaging from "./components/FirebaseMessaging.vue"
import {ref, computed} from "vue"
import BaseLoading from "./components/base/BaseLoading.vue"
import {initAtlaseek} from "@atlaseek/common"
import {OneNotifications} from "@jirizavadil/framework.one/ui"
import {useAppNotificationsStore} from "@jirizavadil/framework.one/ui/src/stores/appNotifications.js"
import {defineStore} from "pinia"
import {usePlanStore} from "./stores/plan.js"
import {useRoleStore} from "./stores/role.js"
import {useDictionaryStore} from "./stores/dictionary.js"
import {useAppStore} from "./stores/app.js"

const route = useRoute()
const router = useRouter()

const appNotificationsStore = useAppNotificationsStore({defineStore})()

const appStore = useAppStore()
const userStore = useUserStore()
const planStore = usePlanStore()
const roleStore = useRoleStore()
const dictionaryStore = useDictionaryStore()

const {app} = storeToRefs(appStore)
const {appNotification} = appNotificationsStore
const {auth: userAuth} = storeToRefs(userStore)
const {userLoaded} = storeToRefs(userStore)

const initialUserCheckDone = ref(false)

const darkMode = computed(() => {
  const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches

  if (app.value.darkMode === "system") {
    return prefersDarkMode ? "dark" : "light"
  } else {
    return `${app.value.darkMode}`
  }
})

onAuthStateChanged(auth, async (firebaseUser) => {
  if (
    firebaseUser?.email?.endsWith("@atlaseek.com") ||
    firebaseUser?.email == "jiri@zavadil.pro" ||
    firebaseUser?.email == "kristina@melsova.com"
  ) {
    userAuth.value = firebaseUser

    await userStore.bind({userId: firebaseUser.uid})

    // re-init atlaseek api
    initAtlaseek({firebaseUser, appNotification})

    const redirectedFrom = router.currentRoute?.value?.redirectedFrom

    if (redirectedFrom && redirectedFrom.fullPath !== "/") {
      router.push(redirectedFrom)
    } else {
      router.push({
        name: "DashboardView",
      })
    }
  } else {
    router.push({
      name: "AuthView",
    })
  }

  initialUserCheckDone.value = true
})

initAtlaseek({appId: "admin", appNotification})

planStore.get()
roleStore.get()
dictionaryStore.get()
</script>

<template>
  <div class="" :data-color-scheme="[darkMode]">
    <div class="flex h-screen flex-center" v-if="!initialUserCheckDone">
      <BaseLoading />
    </div>

    <div v-if="initialUserCheckDone">
      <RouterView />
    </div>

    <FirebaseMessaging v-if="userLoaded" />
    <OneNotifications :appNotificationsStore />
  </div>
</template>
