import enumsPlatformMetricsSnapshotVerificationStatuses from "../enums/platformMetricsSnapshotVerificationStatuses.js"

const optionsPlatformMetricsSnapshotVerificationStatuses =
  enumsPlatformMetricsSnapshotVerificationStatuses.map((status) => {
    return {
      value: status.id,
      label: status.title,
    }
  })

export default optionsPlatformMetricsSnapshotVerificationStatuses
