const config = {
  appId: null,
  apiKey: null,
  firebaseUser: null,
  testMode: false,
  scope: {
    orgId: null,
  },
  appNotification: null, // convenience function to send app notifications automatically (from ./stores/app.js)
}

export const initAtlaseek = ({
  appId,
  apiKey,
  scope,
  firebaseUser,
  appNotification,
}) => {
  if (firebaseUser) {
    config.firebaseUser = firebaseUser
  } else {
    config.apiKey = apiKey
  }
  if (scope) {
    config.scope = scope
  }
  if (appId) {
    config.appId = appId
  }
  if (appNotification) {
    config.appNotification = appNotification
  }
}

export const getConfig = () => config
