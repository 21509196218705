import enumsPlatformsContentKinds from "../enums/platformsContentKinds.js"

const optionsPlatformsContentKinds = enumsPlatformsContentKinds.map(
  (platform) => {
    return {
      value: platform.id,
      label: platform.title,
      platform: platform.platform,
    }
  }
)

export default optionsPlatformsContentKinds
