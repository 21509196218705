import {request} from "../request.js"

export const rolesCreate = async (data, opts) => {
  return request({
    endpoint: "roles",
    method: "POST",
    data: {data},
    opts,
  })
}

export const rolesGet = async (roleId, opts) => {
  return request({
    endpoint: roleId ? `roles/${roleId}` : "roles",
    method: "GET",
    opts,
  })
}

export const rolesUpdate = async (roleId, data, opts) => {
  return request({
    endpoint: `roles/${roleId}`,
    method: "PATCH",
    data: {data},
    opts,
  })
}

export const rolesDelete = async (roleId, opts) => {
  return request({
    endpoint: `roles/${roleId}`,
    method: "DELETE",
    opts,
  })
}
